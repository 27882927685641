.octicon-moon {
    margin-left: 5px;
    margin-top: 2px;
}

.octicon-sun {
    margin-top: 4px;
    margin-left: 4px;
    color: #FFC725;
}

.desktopHeader {
    width: 1350px !important;
}

@media screen and (max-width: 650px) {
    .desktopHeader {
        display: none !important;
    }
    .mobileHeader {
        display: block;
    }
    .actionList {
        display:  block !important;
    }
    
}

@media screen and (min-width: 650px) {
    .mobileHeader {
        display: none !important;
    }
    .actionList {
        display: none !important;
    }
}

